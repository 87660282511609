<template lang="pug">
  b-modal(resizable,
    v-model="show",
    cancel-disabled
    dialog-class="table-modal"
  )
    div
    .modal-title {{ $t('companies.vendors.edit_vendor_dimensions') }}
    .modal-subtitle {{ $t('companies.vendors.edit_vendor_dimensions') }}
    stack
      stack-item
        b-btn(
          variant="primary",
          squared,
          @click="addDimensionItem"
        )
          .mdi.mdi-file-plus-outline
          | {{ $t('actions.add') }}
    rest-table(
      namespace="vendor_dimensions",
      :headers="headers",
      ref="vendorDimensionsTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_not_suggest", slot-scope="{ item }")
        b-form-checkbox(
          v-model="item.not_suggest",
          switch,
          @change="changeNotSuggest(item)"
        )
      template(slot="cel_actions", slot-scope="{ item }")
        stack(spacing="xs")
          stack-item
            b-btn(
              size="xs",
              variant="flat-danger",
              squared,
              @click="deleteDimensionItem(item)"
            )
              .mdi.mdi-trash-can-outline
              | {{ $t('actions.delete') }}
    template(slot="modal-footer")
      b-btn(
        variant="primary"
        squared
        @click="close"
      )
        .mdi.mdi-account-check-outline
        | {{ $t('actions.save') }}
    add-vendor-dimension-modal(ref="AddVendorDimensionModal", @done="reloadVendorDimensions()")

</template>

<script>
import { mapGetters } from "vuex";
import ApiVendorDimensionItems from "@/api/vendorDimensionItems.js"
import AddVendorDimensionModal from "@/modals/Companies/addVendorDimension.vue"

export default {
  components: {
    AddVendorDimensionModal,
  },

  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      companyId: null,
      vendorId: null,
      form: {},
      pagination: {
        sortBy: null,
        sortOrder: null
      },
      headers: [
        {
          key: "id",
          label: "#",
          squeeze: true,
        },
        {
          key: "dimension_name",
          label: "Dimension",
          squeeze: false,
        },
        {
          key: "name",
          label: "Dimension Item",
          squeeze: false,
        },
        {
          key: "not_suggest",
          label: "Don't suggest item",
          squeeze: false,
        },
        {
          key: "actions",
          label: "#",
          squeeze: false,
        }
      ]
    };
  },

  methods: {
    open(companyId, Vendor) {
      this.companyId = companyId;
      this.vendorId = Vendor.id
      this.getData();
      this.show = true;
    },

    close() {
      this.show = false;
    },

    reloadVendorDimensions() {
      this.$refs.vendorDimensionsTable.fetchData();
    },

    getData(pagination) {
      this.inProgress = true;

      return new Promise((res) => {
        ApiVendorDimensionItems.fetchDimensionItems(this.companyId, this.vendorId, pagination)
            .then((resp) => {
              res({
                items: resp.data.vendorDimensions,
                meta: resp.data.meta,
              });
            })
            .catch((e) => {
              this.inProgress = false;
            });
      });
    },

    deleteDimensionItem(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t('actions.confirm_remove') + ' ' + item.dimension_name + ' ' + item.name,
        variant: "danger",
        icon: "trash-can",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiVendorDimensionItems.deassignDimensionItem(this.companyId, this.vendorId, item.id).then(
            (resp) => {
              this.reloadVendorDimensions();
            }
        );
      });

    },

    addDimensionItem() {
      this.$refs.AddVendorDimensionModal.open(this.companyId, this.vendorId);
    },

    changeNotSuggest(item) {
      this.saveItem(item.id, {
        vendor_dimension_item: { not_suggest: item.not_suggest },
      });
    },

    saveItem(id, params) {
      ApiVendorDimensionItems.updateVendorDimensionItem(
          this.$route.params.id,
          id,
          params
      ).then((resp) => {
        this.getData();
      });
    },
  }
}
</script>